import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import "../styles/styles.css"
// https://medium.com/@mariorodriguezan/css-to-change-react-bootstrap-navbar-color-83763c4e13b8

export default function BottomNavbar() {
  return (
    <Navbar fixed="bottom" className="bottom-nav" expand="lg" variant="dark">
      <Container>
          <Nav className="mx-auto">
            <Row>
                <Col>
                    <h4 style={{color: 'white'}}>Business Hours</h4>
                    <hr style={{ borderColor: 'white', borderWidth: 3}}/>
                    <p style={{color: 'white'}}>Mon - Sat: 11am - 5pm</p>
                    <p style={{color: 'white'}}>Sunday: Closed</p>
                </Col>

                <Col>
                    <h4 style={{color: 'white'}}>Our Mission</h4>
                    <hr style={{ borderColor: 'white', borderWidth: 3}}/>
                    <p style={{color: 'white'}}>Commited to professional, realiable, and quality sanitation services with our gurantee of satisfaction.</p>
                </Col>
            </Row>
          </Nav>
      </Container>
    </Navbar>
  );
}
