import "./styles.css";
import BottomNavbar from "./views/BottomNavbar";
import TopNavbar from "./views/TopNavbar";

export default function App() {
  return (
    <div>
      <TopNavbar />
      <BottomNavbar/>
    </div>
  );
}
