import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import emailjs from '@emailjs/browser';

import ReCAPTCHA from 'react-google-recaptcha';
import validator from 'validator'

// const { Captcha } = require('captcha-canvas');
// const { writeFileSync } = require('fs');

// const captcha = new Captcha(); //create a captcha canvas of 100x300.

// captcha.async = false //Sync
// captcha.addDecoy(); //Add decoy text on captcha canvas.
// captcha.drawTrace(); //draw trace lines on captcha canvas.
// captcha.drawCaptcha(); //draw captcha text on captcha canvas.

// console.log(captcha.text); //log captcha text.

//const buffer = captcha.generate
// writeFileSync('captcha.png', buffer); //create 'captcha.png' file in your directory.

// async function sendEmail() {
//   console.log('Send EMAIL')

// }

// site key - 6LfMby4kAAAAAFjNoqYyrK4KamC6aB2Hh0gDMDAz
// site secret - 6LfMby4kAAAAANA26Zx3dNjm0tcvLhc8eqPITY5j

function Contact() {

  const form = useRef()
  const fname = useRef(null);
  const email = useRef(null);
  const subject = useRef(null);
  const message = useRef(null);
  // const captchaRef = useRef(null);

  const sendEmail = (e) => {    
    e.preventDefault();
    /*
    e.preventDefault();

    emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      */



      // const payload = {
      //   from_name: fname.current.value,
      //   message: `${subject.current.value}\n${message.current.value}`,
      //   reply_to: email.current.value,
      //   'g-recaptcha-response': token
      //   };

        const payload = {
          from_name: fname.current.value,
          message: `${subject.current.value}\n${message.current.value}`,
          reply_to: email.current.value,
          };
        // captcha - https://www.emailjs.com/docs/user-guide/adding-captcha-verification/
        // https://www.emailjs.com/docs/sdk/send/

        // console.log(payload)
        emailjs.send("service_gvp8fj9","template_t7ofz4a", payload, "dPnPslMgNg95oUCWo").then((result) => {
          console.log('SUCCESS send', result.text)
          alert("Thank you for your inquiry. Your email was sent. We will reply to you as soon as possible.")
          window.location.reload()
        }, (error) => {
          console.log('FAILED...', error.text)
        })

      // // works **
      // e.preventDefault();
        // https://www.emailjs.com/docs/sdk/send-form/
      // // emailjs.sendForm('service_2tcvt2p', 'template_q30lwde', e.target, 'xLa_CrCZ6e2i261hc') - gorditas durango
      // emailjs.sendForm('service_gvp8fj9', 'template_t7ofz4a', e.target, 'dPnPslMgNg95oUCWo', {
      //   from_name: fname.current.value,
      //   message: `${subject.current.value}\n${message.current.value}`,
      //   reply_to: email.current.value
      // })
      //   .then((result) => {
      //       console.log(result.text);
      //   }, (error) => {
      //       console.log(error.text);
      //   });
  };

  return (
    // js form - https://www.emailjs.com/docs/tutorial/creating-contact-form/
    <Form ref={form} className="contact-form" onSubmit={sendEmail} validated>
              
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>First Name</Form.Label>
              <Form.Control required ref={fname} type="name" placeholder="Enter name" />
            </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control required ref={email} type="email" placeholder="Enter email" />
              </Form.Group>
              
              <Form.Group className="mb-3" controlId="formSubject">
                <Form.Label>Subject</Form.Label>
                <Form.Control required ref={subject} type="text" placeholder="Subject" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control required style={{height: '60px'}} ref={message} type="text" as="textarea" placeholder="Type message here" />
              </Form.Group>

              <Row >
                  {/* <Col>
                      <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <ReCAPTCHA ref={captchaRef} size='compact' sitekey='6LfMby4kAAAAAFjNoqYyrK4KamC6aB2Hh0gDMDAz' />
                      </Form.Group>
                  </Col> */}

                  <Col>
                      <Button variant="primary" type="submit" className='homeButton' size='lg' style={{marginBottom: '40%'}}>
                        Send
                      </Button>
                  </Col>
              </Row>
    </Form>
  );
}

export default Contact;
// https://jan-pro.ca/blog/7-traits-to-look-for-in-a-reputable-business-cleaning-service/
// https://kohlbm.com/blog/top-10-commercial-cleaning-trends/