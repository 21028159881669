import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from 'react-router-dom';

function Services() {
  return (
    <Container style={{ paddingTop: 160, paddingBottom: 200 }}>
      
      <h1 style={{ display:'flex', justifyContent:'center', alignItems: 'center'}}>Our Services</h1>
      <hr style={{ borderColor: 'grey', borderWidth: 5}}/>

      <Row style={{ alignItems: 'center'}} className="text-center">
        <Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 1 }}><h1>Commercial & Residential</h1></Col>
        <Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 2 }}>
          <h4>Have a commercial property or residential area you need sanitized? Allow us to decontaminate and maintain your environment clean and spotless.</h4>
        </Col>
        <Col xs={{ span: 12, order: 3 }} md={{ span: 4, order: 3 }}><Button style={{backgroundColor: '#26B9C7'}} as={Link} to={"/contact"} size='lg'>Get a Quote</Button></Col>
      </Row>
      
      <hr style={{ borderColor: 'grey', borderWidth: 5}}/>

      <Row style={{ alignItems: 'center'}} className="text-center">
        <Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 1 }}><h1>Move Out/In</h1></Col>
        <Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 2 }}>
          <h4>Let us facilitate your move in/out by taking care of the sanitizing allowing you to focus on what matters.</h4>
        </Col>
        <Col xs={{ span: 12, order: 3 }} md={{ span: 4, order: 3 }}><Button style={{backgroundColor: '#26B9C7'}} as={Link} to={"/contact"} size='lg'>Get a Quote</Button></Col>
      </Row>

      <hr style={{ borderColor: 'grey', borderWidth: 5}}/>

      <Row style={{ alignItems: 'center', marginBottom: '10%'}} className="text-center" >
        <Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 1 }}><h1>Deep Cleaning</h1></Col>
        <Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 2 }}>
          <h4>Allow us to deep clean and sanitize thoroughly by decontaminating the hard-to-reach areas of your environment and sorrounding area.</h4>
        </Col>
        <Col xs={{ span: 12, order: 3 }} md={{ span: 4, order: 3 }}><Button style={{backgroundColor: '#26B9C7'}} as={Link} to={"/contact"} size='lg'>Get a Quote</Button></Col>
      </Row>

    </Container>
  );
}

export default Services;

/*
TODO: CHECK OUT
https://www.cleansweepofamerica.com/faqs/


Studio Deep Clean

Service Description
TWO STAFF MEMBERS WILL ATTEND THIS CLEANING SESSION.

This service includes:
Floors: vacuum, mop and all borders
Dust: on all surfaces and furniture
Windows: on the interior
Kitchen: cabinets wiped on the outside and the inside, the stove, inside of the oven, microwave, counters, inside and outside of the refrigerator, and we take out the trash.
Bathroom: well done
Bedroom: we will change the bed sheets if you desire

THE PRICE COULD VARY IF YOU HAVE PETS AND IF THE CLEANING TAKES LONGER THAN EXPECTED.

The Three Tiers of Cleaning
CLEANING: Remove clutter, dust, crumbs, and other detritus.
SANITIZING: Reduce germs and decrease the opportunity for microbes to gather by providing antibacterial solutions.
DISINFECT: Destroy germs on contact by eradicating them where they live and inhibiting their spread at the source.


The cleaning levels are as follows: Level 1 Orderly Spotlessness, Level 2 Ordinary Tidiness, Level 3 Casual Inattention, Level 4 Moderate Dinginess, Level 5 Unkempt Neglect.
*/