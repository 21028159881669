import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { Image, Figure } from 'react-bootstrap';

import { BrowserRouter as Router,
    Routes,
Route,
Link } from 'react-router-dom';

import Home from './Home';
import About from './About';
import Services from './Services'
import Contact from './Contact'

import "../styles/styles.css"
// https://medium.com/@mariorodriguezan/css-to-change-react-bootstrap-navbar-color-83763c4e13b8

// Nav bar links
// https://codesandbox.io/s/nifty-morning-mfcm5w?file=/src/App.js:136-142


export default function TopNavbar() {

  return (
    <Router>
        <div>
    <Navbar collapseOnSelect expand="lg" fixed="top" className="color-nav" variant="dark">
      <Container>
        <Navbar.Brand as={Link} to={"/home"}>       <Image 
            className="mx-auto d-block" 
            src={require("../assets/mss-logo-2023-03-10 at 11.05.14 PM.png")} 
            alt="bg image" 
            fluid style={{ height: 110, width: 200 }}
          />
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">

            <Nav.Link onClick={()=>window.scrollTo(0,0)} eventKey="1" as={Link} to={"/home"}><h3>Home</h3></Nav.Link>
            
            <Nav.Link onClick={()=>window.scrollTo(0, 0)} eventKey="2" as={Link} to={"/about"}><h3>About</h3></Nav.Link>
            
            <h3>
            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={()=>window.scrollTo(0, 0)} href="#action/3.1" as={Link} to={"/services"}>Commercial & Residential</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>window.scrollTo(0, 0)} href="#action/3.2" as={Link} to={"/services"}>Move Out/In</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>window.scrollTo(0, 0)} href="#action/3.3" as={Link} to={"/services"}>Deep Cleaning</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={()=>window.scrollTo(0, 0)} href="#action/3.4" as={Link} to={"/services"}>
                More
              </NavDropdown.Item>
            </NavDropdown>
            </h3>

            <Nav.Link onClick={()=>window.scrollTo(0, 0)} eventKey="4" as={Link} to={"/contact"}><h3>Contact</h3></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
    <div>
    <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/home" element={<Home />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/services" element={<Services />}/>
        <Route path="/contact" element={<Contact />}/>
            
    </Routes>
    </div>
    </Router>

    
  );
}
