import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { Image, Figure } from 'react-bootstrap';
import {Link } from 'react-router-dom'

function About() {
  return (
<Container>
      <Row style={{ paddingTop: 160 }}>
      <Col sm={6}>
          <h1>Excellent Standards</h1>
          <h4>Modern Sanitation Services exceptional performance of cleaning work at an affordable price guarantees 100% satisfaction. Our professional cleaners will check your environment and search for the most contaminated areas. They will make a cleaning plan with all stages, details, and checklist with all necessary supplies.</h4>
          <h1>Let Us Help</h1>
          <h4>Allow us to sanitize your environment by ensuring the decontamination of your area at an unbeatable price.</h4>
          <Button onClick={()=>window.scrollTo(0, 0)} as={Link} to={"/contact"} size='lg' className='aboutButton'><h2>Get a Quote</h2></Button>
        </Col>
        <Col sm={6}>
          <Image 
            src={require("../assets/modern-minimalist-bathroom-gf390213ff_1920.jpg")} 
            alt="bg image" 
            fluid
            style={{ height: 550 }}
          />
        </Col>
      </Row>
      
      <Row style={{ paddingTop: 40, paddingBottom: 200 }}>
      <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
          <Image 
            className="mx-auto d-block" 
            src={require("../assets/hall-g700c7aa1a_1920.jpg")} 
            alt="bg image" 
            fluid style={{ height: 400, width: 600, marginBottom: '10%' }}
          />
        </Col>

      <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
          <h1>Satisfied Customers</h1>
          {/* CHANGE TEXT BELOW */}
          <h4>We ensure an easy process before, during, and after with 10+ years of experience providing reliable, efficient, and professional cleaning services to improve your quality of life.</h4>
        </Col>
      </Row>
    </Container>
  );
}

export default About;