import Button from 'react-bootstrap/Button';
import { Image } from 'react-bootstrap';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {Link } from 'react-router-dom'

import "../styles/styles.css"

// https://stackoverflow.com/questions/59777902/how-center-an-image-with-bootstrap-and-react

function Home() {
  return (
    <div className="d-grid gap-2">
      {/* https://stackoverflow.com/questions/56452405/centering-image-with-react-react-bootstrap-flexbox */}
      <Image
        className="mx-auto d-block"
        src={require("../assets/dining-room-gd768ce32a_1280.jpg")}
        alt="bg image"
        fluid
        style={{ paddingTop: 145, height: 620, width: 900 }}
      />

      <h1 style={{ textAlign: 'center', backgroundColor: "#5fae1f", color: "white" }}>
        Providing quality & professional cleaning services in the greater Los Angeles area
      </h1>
      {/* <div className="d-grid gap-2"> */}

      <Button onClick={()=>window.scrollTo(0, 0)} as={Link} to={"/contact"} size='lg' className='homeButton'><h2>Get a Quote</h2></Button>

      <h1 style={{ textAlign: 'center', backgroundColor: "#5fae1f", color: "white" }}>Why choose us?</h1>

      <Container className='dashed-border-container'>
        <Row>
          <Col sm><h1 className='dashed-border'>Excellent service guaranteed</h1></Col>
          <Col sm><h1 className='dashed-border'>10+ years of experience</h1></Col>
          <Col sm><h1 className='dashed-border'>Affordable competitive rates</h1></Col>
        </Row>

      </Container>
    </div>
  );
}

export default Home;